import { lang } from '../coockies';
const about_start_lang = {
    ge: {
        aboutme: 'ჩვენს შესახებ',
        welcome: 'კეთილი იყოს თქვენი მობრძანება საქართველოში, დაუვიწყარი <span className="text-primary">მოგზაურობისათვის</span>',
        invite: 'ესტუმრეთ საქართველოს ულამაზეს კუთხეებს როგორიცაა ყაზბეგი, იმერეთი, აჭარა… ისიამოვნეთ და გაატარეთ არაჩვეულებრივი დრო.',
        offer: 'GeoTravels არის ტურისტული სააგენტო, რომელიც მრავალი წელია მომხმარებელს სთავაზობს ინდივიდუალურ და ჯგუფურ ტურებს საქართველოს მასშტაბით. <br />ჩვენ ვცდილობთ შევქმნათ კომფორტული ტურები, რათა თქვენი მოგზაურობა იყოს დაუვიწყარი და შთაბეჭდილებებით სავსე.',
        service1: 'კომფორტული ავტომობილები',
        service2: 'ლამაზი და კეთილმოწყობილი სასტუმროები',
        service3: 'ტურები საქართველოს ყველა მიმართულებით',
        service4: 'გიდის მომსახურება (ფასიანი)',
        service5: 'ტურის დაგეგმვა თქვენი მოთხოვნების მიხედვით',
        service6: '24/7 სერვისი',
    },
    en: {
        aboutme: 'About Us',
        welcome: 'Welcome to Georgia for an unforgettable <span className="text-primary">trip</span>',
        invite: 'Visit the most beautiful corners of Georgia, such as Kazbegi, Imereti, Adjara... Enjoy and have an unforgettable time.',
        offer: 'GeoTravels is a travel agency that has been offering its clients individual and group tours in Georgia for many years. <br /> We try to create comfortable tours so that your trip is unforgettable and full of impressions',
        service1: 'comfortable cars',
        service2: 'beautiful and well-equipped hotels',
        service3: 'Tours to all destinations in Georgia',
        service4: 'guide services (paid)',
        service5: 'Tour planning according to your requirements',
        service6: '24/7 service',
    },
    ar: {
        aboutme: 'About Us',
        welcome: 'Welcome to Georgia for an unforgettable <span className="text-primary">trip</span>',
        invite: 'Visit the most beautiful corners of Georgia, such as Kazbegi, Imereti, Adjara... Enjoy and have an unforgettable time.',
        offer: 'GeoTravels is a travel agency that has been offering its clients individual and group tours in Georgia for many years. <br /> We try to create comfortable tours so that your trip is unforgettable and full of impressions',
        service1: 'comfortable cars',
        service2: 'beautiful and well-equipped hotels',
        service3: 'Tours to all destinations in Georgia',
        service4: 'guide services (paid)',
        service5: 'Tour planning according to your requirements',
        service6: '24/7 service',
    },
    ru: {
        aboutme: 'О нас',
        welcome: 'Добро пожаловать в Грузию в незабываемое <span className="text-primary">путешествие</span>',
        invite: 'Посетите самые красивые уголки Грузии, такие как Казбеги, Имеретия, Аджария... Наслаждайтесь и проведите незабываемое время.',
        offer: 'GeoTravels – туристическое агентство, которое уже много лет предлагает своим клиентам индивидуальные и групповые туры в  Грузии.<br> Мы стараемся создавать комфортные туры, чтобы ваше путешествие было незабываемым и полным впечатлений.',
        service1: 'комфортные автомобили',
        service2: 'красивые и хорошо оборудованные отели',
        service3: 'Туры по всем направлениям Грузии',
        service4: 'услуги гида (платные)',
        service5: 'Планирование тура по вашим требованиям',
        service6: '24/7 сервис',
    }
}
const l = about_start_lang[lang];
export { l };