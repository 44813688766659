import { bake_cookie, read_cookie } from 'sfcookies';//, delete_cookie
let lang=read_cookie('lang');

if(lang.length===0){
    const k='en';    
    bake_cookie('lang', k);
    lang=k;
}
function clicklang(e){    
    if(e.target.id==='langGe'){
        bake_cookie('lang', 'ge');
    }else if(e.target.id==='langEn'){
        bake_cookie('lang', 'en');
    }
    const loc=window.location.href;
    window.location.href=loc;
  }

export {lang,clicklang};