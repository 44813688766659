import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { APiUrl ,geoURL} from '../../constants/global';
import { l } from "../../constants/langs/tursLang";
function OurTours() {
    const [tours, setTours] = useState(null);

    useEffect(() => {
        axios
            .post(APiUrl + 'items')
            .then(data => {
                setTours(data.data[0].posts);
            }).catch((error) => {
                console.log(error);
                setTours(false);
              });

    }, []);

    return (
        <>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title bg-white text-center text-primary px-3">{l.head}</h6>
                        <h1 className="mb-5">{l.helpYou}</h1>
                    </div>
                    <div className="row g-4 justify-content-center">
                        {tours && tours.map(tour => {
                            return (
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" key={tour.id}>
                                    <div className="package-item">
                                        <div className="overflow-hidden">
                                            
                                            <img className="img-fluid" src={tour.img} alt={tour.city} style={{maxHeight:'264px',width:'100%'}} />
                                        </div>
                                        <div className="d-flex border-bottom">
                                            <small className="flex-fill text-center border-end py-2"><i className="fa fa-map-marker-alt text-primary me-2"></i>{tour.city}</small>
                                            <small className="flex-fill text-center border-end py-2"><i className="fa fa-calendar-alt text-primary me-2"></i>{tour.period} {l.day}</small>
                                            <small className="flex-fill text-center py-2"><i className="fa fa-user text-primary me-2"></i>{tour.person} {l.person}</small>
                                        </div>
                                        <div className="text-center p-4">
                                            <h3 className="mb-0">{tour.price} {l.price[tour.valuta]}</h3>
                                            <div className="mb-3">
                                                
                                            </div>
                                            <p> <strong>{tour.city}</strong> <br />{tour.shortext}</p>
                                            <div className="d-flex justify-content-center mb-2">
                                            <NavLink to={`${geoURL}ToursDetails/${tour.id}/${tour.city}`} style={{ borderRadius: "5px" }} className="btn btn-sm btn-primary px-3">{l.booking}</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}
export default OurTours;
