import { lang } from '../coockies';
import { l } from "./navbar";
const contact = {
    ge: {
        Contactus:'დაგვიკავშირდით',
        text1:'მოგვწერეთ, გიპასუხებთ ჩვენი ტურის დეტალურ ინფრომაციაზე',
        Ouroffice:'ჩვენი ოფისი',
        text2:'ჩვენი ოფისი მდებარეობს საქართველოს უძველესი ისტორიის მქონე ქალაქ ქუთაისში',
        location:'ქუთაისი',
        yourname:'სახელი',
        yourmail:'ელ. ფოსტა',
        subject:'წერილის სათაური',
        message:'დატოვე შეტყობინება აქ',
        send:'წერილის გაგზავნა',
        price:['ლარი','დოლარი','ევრო'],
    },
    en: {
        Contactus:'Contact us',
        text1:'Write to us, we will answer you with detailed information about our tour',
        Ouroffice:'Our office',
        text2:'Our office is located in Kutaisi, a city with ancient history in Georgia',
        location:'Kutaisi',
        yourname:'Your name',
        yourmail:'Your e-mail',
        subject:'Subject',
        message:'Leave a message here',
        send:'Send',
        price:['Lari','Dolar','Euro'],

    },
    ar: {
        Contactus:'Contact us',
        text1:'Write to us, we will answer you with detailed information about our tour',
        Ouroffice:'Our office',
        text2:'Our office is located in Kutaisi, a city with ancient history in Georgia',
        location:'Kutaisi',
        yourname:'Your name',
        yourmail:'Your e-mail',
        subject:'Subject',
        message:'Message',
        send:'Send',
        price:['Lari','Dolar','Euro'],
    },
    ru: {
        Contactus:'Свяжитесь с нами',
        text1:'Напишите нам, мы ответим Вам с подробной информацией о нашем туре',
        Ouroffice:'Наш офис',
        text2:'Наш офис расположен в Кутаиси, городе с древней историей в Грузии',
        location:'Кутаиси',
        yourname:'Имя',
        yourmail:'Электронная почта',
        subject:'Тема сообщения',
        message:'Оставьте сообщение здесь',
        send:'Отправить',
        price:['Лари','Долар','Евро'],
    }
}
const l0 = contact[lang];
const g= {...l, ...l0};;

export { g };