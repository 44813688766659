import { lang } from '../coockies';
const service_lang = {
    ge: {
        head: 'მომსახურება',
        offer: 'ჩვენ გთავაზობთ',
        turs: 'ტურებს საქართველოში',
        turstext: 'დაუვიწყარი ტურების დაგეგმვა საქართველოს მასშტაბით.',
        hotel: 'სასტუმროს დაჯავშნა',
        hoteltext: 'მხოლოდ ტურის ფარგლებში სასურველი სასტუმროს დაჯავშნა/დარეზერვება.',
        gidi: 'გიდი',
        giditext: 'გიდის მომსახურება ტურის განმავლობაში (დამატებითი საფასურის გადახდისას)',
        events: 'ღონისძიებების დაჯავშნა',
        eventstext: 'ტურის ფარგლებში გასართობი ღონისძიებების დაგეგმვა/მოწყობა',
        price:['ლარი','დოლარი','ევრო'],
    },
    en: {
        head: 'Service',
        sentence: "We offer",
        turs: 'Tours to Georgia',
        turstext: 'We are planning unforgettable tours around Georgia.',
        hotel: 'Hotel booking',
        hoteltext: 'Book your desired hotel only as part of the tour.',
        gidi: 'Guide',
        giditext: 'Guide services during the excursion (if an additional fee is paid)',
        events: 'Planning events',
        eventstext: 'Planning/organizing entertainment events during the tour',
        price:['Lari','Dolar','Euro'],
    },
    ru: {
        head: 'Сервис',
        sentence: "Мы предлагаем",
        turs: 'Туры в Грузию',
        turstext: 'Мы планируем незабываемые туры по Грузии.',
        hotel: 'Бронирование отеля',
        hoteltext: 'Забронируйте желаемый отель только в рамках тура.',
        gidi: 'Услуги гида',
        giditext: 'Услуги гида во время экскурсии (при дополнительной оплате)',
        events: 'Планирование мероприятий',
        eventstext: 'Планирование/организация развлекательных мероприятий во время тура',

        price:['Лари','Долар','Евро'],
    },
    ar: {
        head: 'Service',
        sentence: "We offer",
        turs: 'Tours to Georgia',
        turstext: 'We are planning unforgettable tours around Georgia.',
        hotel: 'Hotel booking',
        hoteltext: 'Book your desired hotel only as part of the tour.',
        gidi: 'Guide',
        giditext: 'Guide services during the excursion (if an additional fee is paid)',
        events: 'Backup events',
        eventstext: 'Planning/organizing entertainment events during the tour',
        price:['Lari','Dolar','Euro'],
    },
}
const l = service_lang[lang];
export { l };