import { l } from "../../constants/langs/navbar";
import Galery from "./Galerea";

function PageFooter() {
    
    return (
        <>
            <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-white mb-3">Geo Travels</h4>
                            <a className="btn btn-link" href="/">{l.main}</a>
                            <a className="btn btn-link" href="/Tours">{l.Tours}</a>
                            <a className="btn btn-link" href="/About">{l.AboutMe}</a>
                            <a className="btn btn-link" href="/Contact">{l.Contact}</a>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-white mb-3">{l.Contact2}</h4>
                            <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i><a href="http://maps.google.com/?q=42.268949,42.704097" rel="noreferrer" target="_blank">{l.address}</a></p>
                            <p className="mb-2"><i className="fab fa-whatsapp"></i><a href={`https://wa.me/${process.env.REACT_APP_WhatsApp}`}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Whatsapp</a></p>
                            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i><a href={`tel:${process.env.REACT_APP_Phone}`}>{process.env.REACT_APP_Phone}</a></p>
                            
                            <p className="mb-2"><i className="fa fa-envelope me-3"></i><a href="mailto:geotravelsgeo@gmail.com">geotravelsgeo@gmail.com</a></p>
                            <div className="d-flex pt-2">
                                <a className="btn btn-outline-light btn-social" href={process.env.REACT_APP_fb} target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href="/#"><i className="fab fa-instagram fw-normal"></i></a>
                                {/* <a className="btn btn-outline-light " href={`https://wa.me/${process.env.REACT_APP_WhatsApp}`}><i className="fab fa-whatsapp"></i> Whatsapp</a> */}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-white mb-3">{l.galerea}</h4>
                            <div className="row g-2 pt-2">
                                <Galery />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h4 className="text-white mb-3">{l.location}</h4>
                                                        
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a className="border-bottom" href="/">Geotravels.ge</a>, {l.alr}.
                                Designed By <a className="border-bottom" href="https://ramazi.pvt.ge">Ramazi Maisashvili</a>
                            </div>
                            <div className="col-md-6 text-center text-md-end">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PageFooter;