import React, { useEffect, useState } from "react";
import {lang} from "../../constants/coockies";
import axios from "axios";
import { APiUrl, geoURL } from '../../constants/global';
import "../../css/hotels.css";
export default function Hotels() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hotels, setHotels] = useState(null);

    useEffect(() => {
        axios
            .post(APiUrl + 'Hotels')
            .then(data => {
                
                setHotels(data.data);
                setIsLoaded(true);
                setError(null);

            })
            .catch((error) => {
                setHotels(false);
                setIsLoaded(true);
                setError(error);
            })
    }, []);
    if (error) {
        return <div>error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading ...</div>;
    } else {
        return (
            <>
                <div className="row">



                    {hotels && hotels.map(hotel => {
                        return (
                            // <div className="hotel_container" key={hotel.id}>
                            //     <img src={`${geoURL}images/hotels/${hotel.img}`} alt={`GeoTravels Tours ${hotel.name}`} className="hotel_image" />
                            //     <div className="hotel_overlay">
                            //         <div className="hotel_text">{hotel.name}</div>
                            //     </div>

                            // </div>
                            <div className="hotel_column" key={hotel.id}>
                                <div className="hotel_card">
                                    <div className="hotel_img_div" style={{"backgroundImage":`url(${geoURL}images/hotels/${hotel.img})`}}>
                                    {/* <img src={`${geoURL}images/hotels/${hotel.img}`} alt={`GeoTravels Tours ${hotel.name}`} className="hotel_image responsive" /> */}
                                </div>
                                    <h3>{hotel.name}</h3>
                                    <p><a href={hotel.link} hrefLang={lang} rel="noopener noreferrer" target="_blank">{hotel.address}</a></p>

                                </div>
                            </div>

                        )
                    }
                    )
                    }

                </div>
            </>
        )
    }
}