import { lang } from '../coockies';
const about_start_lang = {
    ge: {
        OrderNow: 'შეუკვეთეთ ახლავე',
        Placingorder: 'შეკვეთის გაფორმება ინტერნეტით',
        text1: 'გააფორმეთ შეკვეთა ონლაინ - ინტერნეტის საშუალებით. დაჯავშნეთ ტური საქართველოში.',
        text2: 'ან მობრძანდით ჩვენთან ოფისში და გააფორმეთ შეკვეთა ოპერატორის დახმარებით',
        Booktour: 'დაჯავშნეთ ტური',
        contactname: 'საკონტაქტო სახელი',
        Contactemail: 'საკონტაქტო ელ. ფოსტა',
        tour: 'ტური',
        Contactnumber: 'WhatsApp',
        Additional: 'დამატებითი სურვილები',
        reservation: "დაჯავშნა",
        price:['ლარი','დოლარი','ევრო'],
    },
    en: {
        OrderNow: 'Order Now',
        Placingorder: 'Placing an order online',
        text1: 'Place an order online - via the Internet. Book a tour in Georgia',
        text2: 'Place an order online - via the Internet. Book a tour in Georgia',
        Booktour: 'Book a tour',
        contactname: 'Contact name',
        Contactemail: 'Contact email',
        tour: 'tour',
        Contactnumber: 'WhatsApp',
        Additional: 'Special Request',
        reservation: "Reservation",
        price:['Lari','Dolar','Euro'],
    },
    ar: {
        OrderNow: 'Order Now',
        Placingorder: 'Placing an order online',
        text1: 'Place an order online - via the Internet. Book a tour in Georgia',
        text2: 'Place an order online - via the Internet. Book a tour in Georgia',
        Booktour: 'Book a tour',
        contactname: 'Contact name',
        Contactemail: 'Contact email',
        tour: 'tour',
        Contactnumber: 'WhatsApp',
        Additional: 'Special Request',
        reservation: "Reservation",
        price:['Lari','Dolar','Euro'],
    },
    ru: {
        OrderNow: 'Order Now',
        Placingorder: 'Placing an order online',
        text1: 'Place an order online - via the Internet. Book a tour in Georgia',
        text2: 'Place an order online - via the Internet. Book a tour in Georgia',
        Booktour: 'Book a tour',
        contactname: 'Contact name',
        Contactemail: 'Contact email',
        tour: 'tour',
        Contactnumber: 'WhatsApp',
        Additional: 'Дополнительные пожелания',
        reservation: "Бронирование",
        price:['Лари','Долар','Евро'],
    }
}
const l = about_start_lang[lang];
export { l };