
import { BrowserRouter, Routes, Route } from "react-router-dom";

import NoPage from "./pages/NoPage";

import Home from './pages/en/Home';
import Topbar from "./components/en/Topbar";
import Navbar from "./components/en/Navbar";
import Aboutme from './pages/en/Aboutme';
import Contact from './pages/en/Contact';
import Galerea from './components/en/Galery';
import Hotels from './components/en/Hotels';
import Tours from './pages/en/Tours';
import ToursDetails from './pages/en/ToursDetails';
import PageFooter from './components/en/PageFooter';
import SendSuccessfully from './components/en/SendSuccessfully';
import ErrorSend from './components/en/ErrorSend';

export default function App() {
  return (<>
    <BrowserRouter>
      <Topbar />
      <Navbar />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/Tours" element={<Tours />} />
        <Route path="/About" element={<Aboutme />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Galery" element={<Galerea />} />
        <Route path="/Hotels" element={<Hotels />} />
        <Route path="/ToursDetails/:id/:tours" element={<ToursDetails />} />
        <Route path="/SendSuccessfully" element={<SendSuccessfully />} />
        <Route path="/ErrorSend" element={<ErrorSend />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
      <PageFooter />
    </BrowserRouter>
  </>
  );
}
