
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import { l } from "../../constants/langs/booking";
import { APiUrl,geoURL } from '../../constants/global';
import BookingNow from "../../components/en/BookNow";

export default function ToursDetails() {
    const p = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    useEffect(() => {
        
        fetch(APiUrl + "EditPost", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },// eslint-disable-next-line
            body: JSON.stringify(['', 'EditPost', p.id])
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result.post);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                    console.log(error);
                }
            )
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

        return (
            <>
                {items && <>
                    <div className="container-xxl py-5" style={{ backgroundColor: 'rgb(241 241 241)' }}>
                        <div className="container">
                            <div className="row g-5">
                                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: "400px" }}>
                                    <div className="position-relative h-100">
                                        <img className="img-fluid position-absolute w-100 h-100" src={items.img} alt="" style={{ objectFit: "cover" }} />
                                    </div>
                                </div>
                                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                                    <h6 className="section-title  text-start text-primary pe-3" style={{ backgroundColor: 'rgb(241 241 241)' }}>{Parser(items.city)}</h6>
                                    <h1 className="mb-4">{Parser(items.shortext)}</h1>
                                    <p className="mb-4">{items.period} Day - {items.person} person. price {items.price} {l.price[items.valuta]} </p>
                                    <div className="mb-4">
                                        {Parser(items.text)}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <BookingNow id={items.id} value={items.city}/>

                </>}
            </>
        );
    }
}