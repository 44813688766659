const protocol=window.location.protocol;
const host=window.location.hostname;
const port= window.location.port;

// eslint-disable-next-line
const DefaultUrl = protocol+"//"+host+(port==80 || port==443?'':":"+port) ;
//const APiUrl=protocol+"//"+host+"/api/";
const APiUrl="https://geotravels.ge/api/";
const geoURL="https://geotravels.ge/";
const images_upload_url= APiUrl+"server.php";

const AdminUrl=DefaultUrl+"/";


const itemUrl=APiUrl+"items";
const testUrl=APiUrl+"test.php";

function isset(ref) { return typeof ref !== 'undefined' }


export { DefaultUrl, images_upload_url, AdminUrl,APiUrl,isset ,testUrl,itemUrl ,geoURL};

    
