import React, { useEffect, useState } from "react";
import Parser from 'html-react-parser';
import axios from "axios";
import { l } from "../../constants/langs/aboutstart";
import { APiUrl, geoURL } from '../../constants/global';
var galery=false;
axios
            .post(APiUrl + 'Galery')
            .then(data => {
                galery=data.data;
                
            })
            .catch((error) => {
                console.log(error);
            })
function Aboutstart() {
     const [currentImage, setCurrentImage] = useState("images/galery/about.jpg");


    useEffect((galery) => {
        
            const intervalId = setInterval((galery) => {
            if (galery) { 
               
                setCurrentImage(geoURL+"images/galery/"+(galery[Math.floor(Math.random() * galery.length)])); 
            }
            }, 10000)

            return () => clearInterval(intervalId);
       

    }, [])
    return (
        <>
            <div className="container-xxl py-5" style={{ backgroundColor: 'rgb(241 241 241)' }}>
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: "400px" }}>
                            <div className="position-relative h-100">
                                <img className="img-fluid position-absolute w-100 h-100" src={currentImage} alt="" style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                            <h6 className="section-title  text-start text-primary pe-3" style={{ backgroundColor: 'rgb(241 241 241)' }}>{Parser(l.aboutme)}</h6>
                            <h1 className="mb-4">{Parser(l.welcome)}</h1>
                            <p className="mb-4">{Parser(l.invite)}</p>
                            <p className="mb-4">
                                {Parser(l.offer)}
                            </p>
                            <div className="row gy-2 gx-4 mb-4">
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>{Parser(l.service1)}</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>{Parser(l.service2)}</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>{Parser(l.service3)}</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>{Parser(l.service4)}</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>{Parser(l.service5)}</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>{Parser(l.service6)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Aboutstart;