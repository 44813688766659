import { lang } from '../coockies';
const turs_lang = {
    ge: {
        head: 'ტურები',
        helpYou: 'ჩვენ დაგეხმარებით',
        day: 'დღე',
        person: 'პერსონა',
        info: 'ტურის შესახებ დამატებითი ინფორმაციისათვის მოგვმართეთ',
        booking: 'დაჯავშნა',
        details: 'სულად ...',
        price:['ლარი','დოლარი','ევრო'],
    },
    en: {
        head: 'Tours',
        helpYou: 'We will help you',
        day: 'Day',
        person: 'Person(s)',
        info: 'Contact us for more information about the tour',
        booking: 'Booking',
        details: 'Read more ...',
        price:['Lari','Dolar','Euro'],
    },
    ar: {
        head: 'Tours',
        helpYou: 'We will help you',
        day: 'Day',
        person: 'Person',
        info: 'Contact us for more information about the tour',
        booking: 'Booking(s)',
        details: 'Read more ...',
        price:['Lari','Dolar','Euro'],
    },
    ru: {
        head: 'Туры',
        helpYou: 'Мы поможем тебе',
        day: 'день',
        person: 'Человек',
        info: 'Свяжитесь с нами для получения дополнительной информации о туре',
        booking: 'Бронирование',
        details: 'Читать далее ...',
        price:['Лари','Долар','Евро'],
    },
}
const l = turs_lang[lang];
export { l ,lang};