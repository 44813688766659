import { lang } from '../coockies';
const navbar_lang = {
    ge: {

        main: 'მთავარი',
        Tours: 'ტურები',
        AboutMe: 'ჩვენს შესახებ',
        Contact: 'კონტაქტი',
        Contact2: 'საკონტაქტო ინფორმაცია',
        Lozung1: 'უბრალოდ გაერთე',
        Lozung2: 'შავი ზღვა, ულამაზესი მთიანი კურორტები და ულევი გართობის ადგილები',
        address:'საქართველო, ქუთაისი, წერეთლის ქ. 12',
        galerea:'ფოტოგალერეა',
        location:'მდებარეობა',
        alr:'ყველა უფლება დაცულია',
        Galery:'გალერეა',
        Hotels:'სასტუმროები',
    },
    en: {

        main: 'Main',
        Tours: 'Tours',
        AboutMe: 'About Me',
        Contact: 'Contact',
        Contact2: 'Contact Information',
        Lozung1: 'just have fun',
        Lozung2: 'The Black Sea, beautiful mountain resorts and countless entertainment venues',
        address:'12 Tsereteli Str., Kutaisi, Georgia',
        galerea:'Photo gallery',
        location:'Location',
        alr:'All rights reserved',
        Galery:'Gallery',
        Hotels:'Hotels',
    },
    ru: {

        main: 'Главный',
        Tours: 'Туры',
        AboutMe: 'О нас',
        Contact: 'Контакт',
        Contact2: 'Контактная информация',
        Lozung1: 'Просто веселиться',
        Lozung2: 'Черное море, красивые горные курорты и бесчисленные развлекательные заведения.',
        alr:'Все права защищены',
        Galery:'Галерея',
        Hotels:'Гостиницы',
    },
    ar: {
        main: 'Main',
        Tours: 'Tours',
        AboutMe: 'About Me',
        Contact: 'Contact',
        Contact2: 'Contact Information',
        Lozung1: 'just have fun',
        Lozung2: 'The Black Sea, beautiful mountain resorts and countless entertainment venues',
        alr:'All rights reserved',
        Galery:'Gallery',
        Hotels:'Hotels',
    },
}
const l = navbar_lang[lang];
export { l };