import { l } from "../../constants/langs/booking";
import { APiUrl } from '../../constants/global';
function BookNow(props) {
   
    return (
        <>
            <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="booking p-5">
                        <div className="row g-5 align-items-center">
                            <div className="col-md-6 text-white">
                                <h6 className="text-white text-uppercase">{l.OrderNow}</h6>
                                <h1 className="text-white mb-4">{l.Placingorder}</h1>
                                <p className="mb-4">{l.text1} </p>
                                <p className="mb-4">{l.text2}</p>

                            </div>
                            <div className="col-md-6">
                                <h1 className="text-white mb-4">{l.Booktour}</h1>
                                <form acceptCharset="utf-8" action={`${APiUrl}booking.php`} method="post" encType="multipart/form-data">
                                <input type="hidden" className="form-control bg-transparent" id="id" name="id" value={props.id} placeholder={l.contactname} required/>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control bg-transparent" id="name" name="name" placeholder={l.contactname} required/>
                                                <label htmlFor="name">{l.contactname}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="email" className="form-control bg-transparent" id="email" name="email" placeholder={l.Contactemail} required/>
                                                <label htmlFor="email">{l.Contactemail}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating date" data-target-input="nearest">
                                                <input type="text" defaultValue={props.value}  className="form-control bg-transparent " id="tour" name="tour" placeholder={l.tour}  required/>
                                                <label htmlFor="tour">{l.tour}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="phone" className="form-control bg-transparent" id="phone" name="phone" placeholder={l.Contactnumber} required/>
                                                <label htmlFor="phone">{l.Contactnumber}</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea className="form-control bg-transparent" placeholder="Special Request" id="message" name="message" style={{ height: "100px" }} required></textarea>
                                                <label htmlFor="message">{l.Additional}</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                        
                                            <button className="btn btn-outline-light w-100 py-3" type="submit">{l.reservation}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BookNow;