import React, { useEffect, useState } from "react";
import Fancybox from "./Fancybox";
import axios from "axios";
import { APiUrl,geoURL } from '../../constants/global';
import "../../css/galerea.css";

export default function Galery() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [galeri, setGaleri] = useState(null);

    useEffect(() => {
        axios
            .post(APiUrl + 'Galery', {rows:'all' })
            .then(data => {
                setGaleri(data.data);
                setIsLoaded(true);
                setError(null);

            })
            .catch((error) => {
                setGaleri(false);
                setIsLoaded(true);
                setError(error);
            })
    }, []);
    if (error) {
        return <div>error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading ...</div>;
    } else {
        return (
            <>
                <Fancybox
                    options={{
                        Carousel: {
                            infinite: false,
                        },
                    }}
                >

                    {galeri && galeri.map(galeria => {
                        return (
                            <a data-fancybox="gallery" className="fgalerea" href={`${geoURL}images/galery/${galeria}`} key={galeria}>
                                <img src={`${geoURL}images/galery/${galeria}`} width="200" height="150" alt="GeoTravels Tours" />
                            </a>

                        )
                    }
                    )
                    }
                </Fancybox>
            </>
        )
    }
}