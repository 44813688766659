import { clicklang } from "../../constants/coockies";
import { l } from "../../constants/langs/navbar";
function Topbar() {
    return (
        <>
            <div className="container-fluid bg-dark px-5 d-none d-lg-block">
                <div className="row gx-0">
                    <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center" style={{height: "45px"}}>
                            <small className="me-3 text-light"><i className="fa fa-map-marker-alt me-2"></i><a href="http://maps.google.com/?q=42.268949,42.704097" rel="noreferrer"  target="_blank">{l.address}</a></small>
                            <small className="me-3 text-light"><i className="fa fa-phone-alt me-2"></i><a href={`tel:${process.env.REACT_APP_Phone}`}>{process.env.REACT_APP_Phone}</a></small>
                            <small className="me-3 text-light"><i className="fab fa-whatsapp me-2"></i><a href={`https://wa.me/${process.env.REACT_APP_WhatsApp}`}>{process.env.REACT_APP_Phone}</a></small>
                            <small className="text-light"><i className="fa fa-envelope-open me-2"></i><a href="mailto:geotravelsgeo@gmail.com">geotravelsgeo@gmail.com</a></small>
                            &nbsp;&nbsp;&nbsp;
                            <small className="text-light" ><a className="btn-social" href={process.env.REACT_APP_fb} target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a></small>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center text-lg-end">
                        <div className="d-inline-flex align-items-center" style={{height: "45px"}}>
                            <button className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2 overflow-hidden" onClick={clicklang}  ><img id="langGe" src="/img/ge.png" className="img-fluid" style={{maxWidth:'100%'}} alt="GeoTravels Georgian"/></button>
                            <button className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2 overflow-hidden" onClick={clicklang} ><img id="langEn" src="/img/en.png" className="img-fluid" style={{maxWidth:'100%'}} alt="GeoTravels English"/></button>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Topbar;