import AboutStart from '../../components/en/AboutStart';
import Services from '../../components/en/Service';
import OurTour from "../../components/en/OurTour";
import BookNow from "../../components/en/BookNow";


function Home() {
    return (
        <>
            <AboutStart />
            <Services />
            <OurTour />
            <BookNow />
        </>
    )
}
export default Home;