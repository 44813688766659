
import { l } from "../../constants/langs/navbar";
import { geoURL } from '../../constants/global';
import { NavLink } from "react-router-dom";
function Navbar() {
    
    return (
        <>
            <div className="container-fluid position-relative p-0">
                <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
                    <a href={geoURL} className="navbar-brand p-0">
                        <img src={`${geoURL}img/logo-1.png`} alt="Logo" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="fa fa-bars"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto py-0">
                        <NavLink to="/"  className="nav-item nav-link" activeclassname="active">{l.main}</NavLink>
                        <NavLink to="/Tours"  className="nav-item nav-link" activeclassname="active">{l.Tours}</NavLink>
                        <NavLink to="/About"  className="nav-item nav-link" activeclassname="active">{l.AboutMe}</NavLink>
                        <NavLink to="/Galery"  className="nav-item nav-link" activeclassname="active">{l.Galery}</NavLink>
                        <NavLink to="/Hotels"  className="nav-item nav-link" activeclassname="active">{l.Hotels}</NavLink>
                        <NavLink to="/Contact"  className="nav-item nav-link" activeclassname="active">{l.Contact}</NavLink>

                        </div>
                    </div>
                </nav>

                <div className="container-fluid bg-primary py-5 mb-5 hero-header">
                    <div className="container py-5">
                        <div className="row justify-content-center py-5">
                            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
                                <h1 className="display-3 text-white mb-3 animated slideInDown">{l.Lozung1}</h1>
                                <p className="fs-4 text-white mb-4 animated slideInDown">{l.Lozung2}</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Navbar;