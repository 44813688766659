import React, { useEffect, useState } from "react";

import axios from "axios";
import { APiUrl ,geoURL} from '../../constants/global';
import "../../css/galerea.css";

export default function Galery() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [galeri, setGaleri] = useState(null);

    useEffect(() => {
        axios
            .post(APiUrl + 'Galery',{rows:'6' })
            .then(data => {
                setGaleri(data.data);
                setIsLoaded(true);
                setError(null);

            })
            .catch((error) => {
                setGaleri(false);
                setIsLoaded(true);
                setError(error);
            })
    }, []);
    if (error) {
        return <div>error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading ...</div>;
    } else {
        return (
            <>
                {galeri && galeri.map(galeria => {
                    return (
                        <div className="col-4 img-footer-galerea"  key={galeria}>
                            <img className="img-fluid bg-light p-1" src={`${geoURL}images/galery/${galeria}`} alt="GeoTravels Tours" />
                        </div>
                    )
                }
                )
                }

            </>
        )
    }
}