import { g } from "../../constants/langs/contact";
import { geoURL } from '../../constants/global';
function Contact() {
    return (
        <>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title bg-white text-center text-primary px-3">{g.Contactus}</h6>
                        <h1 className="mb-5">{g.text1}</h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <h5>{g.Ouroffice}</h5>
                            <p className="mb-4">{g.text2}</p>
                            <div className="d-flex align-items-center mb-4">
                                <div className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary" style={{width: "100px", height: "100px"}}>
                                    <i className="fa fa-map-marker-alt text-white"></i>
                                </div>
                                <div className="ms-3">
                                    <h5 className="text-primary">{g.location}</h5>
                                    <p className="mb-0"><a href="http://maps.google.com/?q=42.268949,42.704097" rel="noreferrer" target="_blank">{g.address}</a></p>
                                    <p className="mb-0"><i class="fa fa-phone-alt me-2"></i> <a href="tel:+995593527724" target="_blanck">+995593527724</a></p>
                                    <p className="mb-0"><i class="fab fa-whatsapp me-2"></i> <a href="https://wa.me/995593527724">+995593527724</a></p>
                                    <p className="mb-0">geotravelsgeo@gmail.com</p>
                                    
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                           <img src={`${geoURL}img/map.jpg`} style={{width:"300px"}} alt="geotravels location" />
                        </div>
                        <div className="col-lg-4 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                            <form action="/api/sendmail.php" method="post" encType="multipart/form-data">
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" id="name" name="name" placeholder={g.yourname} />
                                                <label htmfor="name">{g.yourname}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="email" className="form-control" id="email" name="email" placeholder={g.yourmail} />
                                                <label htmfor="email">{g.yourmail}</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" id="subject" name="subject" placeholder={g.subject} />
                                                <label htmfor="subject">{g.subject}</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control" placeholder={g.message} id="message" name="message"  style={{height: "100px"}}></textarea>
                                            <label htmfor="message">{g.message}</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-primary w-100 py-3" type="submit">{g.send}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Contact;